import React from 'react';

import './ListWithHeading.scss';

const ListWithHeading = ({icon, title, items, titleTag: Tag}) => (
  <div className="list-with-heading">
    <div className="list-with-heading__top">
      {icon && <div className="list-with-heading__icon">{icon}</div>}
      <div className="list-with-heading__title"><Tag>{title}</Tag></div>
    </div>
    <div className="list-with-heading__content">
      <ul className="list-with-heading__list">
        {items.map(item => <li key={item} className="list-with-heading__item" dangerouslySetInnerHTML={{__html: item}}/>)}
      </ul>
    </div>
  </div>
);

ListWithHeading.defaultProps = {
  titleTag: "span"
};

export default ListWithHeading;
