import React from 'react';
import StandardPageComponent from "../../components/Base/StandardPageComponent";
import withLayout from "../../layout";
import {FormattedHTMLMessage, injectIntl} from "react-intl";
import {graphql, StaticQuery} from "gatsby";
import Image from "../../components/layout/Sections/Image";
import ContactSlide from "../../components/Contact/ContactSlide";
import SectionColumn from "../../components/layout/Sections/SectionColumn";
import TextContent from "../../components/Typography/TextContent";
import Section from "../../components/layout/Sections/Section";
import Spacer from "../../components/layout/Helpers/Spacer";
import BardziejButton from "../../components/BardziejButton";
import CompareImage from "../../components/layout/Images/CompareImage";
import Img from "gatsby-image";
import FeaturesCircle from "../../components/Kits/FeaturesCircle";
import Tabs from "../../components/UI/Tabs/Tabs";
import ListWithHeading from "../../components/Kits/ListWithHeading";
import BorderGrid from "../../components/layout/Grid/BorderGrid";
import Steps from "../../components/Kits/Steps";
import Reviews from "../../components/Kits/Reviews";
import Popup from "../../components/UI/Popup/Popup";
import PopupHeading from "../../components/UI/Popup/PopupHeading";
import PopupContent from "../../components/UI/Popup/PopupContent";
import LineItems, {LineTop, LineBottom} from "../../components/Kits/LineItems";

// icons
import CircleIcon1 from '../../shared/icons/shop/shop-shield.svg';
import CircleIcon2 from '../../shared/icons/shop/shop-lock.svg';
import CircleIcon3 from '../../shared/icons/shop/shop-menu.svg';
import CircleIcon4 from '../../shared/icons/shop/shop-serwer.svg';
import CircleIcon5 from '../../shared/icons/shop/shop-cms.svg';
import CircleIcon6 from '../../shared/icons/shop/shop-list.svg';
import CircleIcon7 from '../../shared/icons/shop/shop-hardware.svg';
import CircleIcon8 from '../../shared/icons/shop/shop-web.svg';

import ListIcon1 from '../../shared/icons/shop/shop-line.svg';
import ListIcon2 from '../../shared/icons/shop/shop-6.svg';
import ListIcon3 from '../../shared/icons/shop/shop-7.svg';
import ListIcon4 from '../../shared/icons/shop/shop-8.svg';
import ListIcon5 from '../../shared/icons/shop/shop-9.svg';
import ListIcon6 from '../../shared/icons/shop/shop-10.svg';
import ListIcon7 from '../../shared/icons/shop/shop-11.svg';
import ListIcon8 from '../../shared/icons/shop/shop-list.svg';

import TabIcon1 from '../../shared/icons/shop/shop-photo.svg';
import TabIcon2 from '../../shared/icons/shop/shop-cinema.svg';
import TabIcon3 from '../../shared/icons/shop/shop-tag.svg';
import TabIcon4 from '../../shared/icons/shop/shop-commerce.svg';

import TabIcon5 from '../../shared/icons/shop/shop-music.svg';
import TabIcon6 from '../../shared/icons/shop/shop-glass.svg';
import TabIcon7 from '../../shared/icons/shop/shop-allegro.svg';
import TabIcon8 from '../../shared/icons/shop/shop-nation.svg';

import StepIcon1 from '../../shared/icons/shop/shop-1.svg';
import StepIcon2 from '../../shared/icons/shop/shop-4.svg';
import StepIcon3 from '../../shared/icons/shop/shop-3.svg';
import StepIcon4 from '../../shared/icons/shop/shop-2.svg';

import PopupIcon1 from '../../shared/icons/shop/shop-sport.svg';
import PopupIcon2 from '../../shared/icons/shop/shop-pen.svg';

import PopupIcon3 from '../../shared/icons/shop/shop-web-design.svg';
import PopupIcon4 from '../../shared/icons/shop/shop-people.svg';
import PopupIcon5 from '../../shared/icons/shop/shop-pen.svg';
import PopupIcon6 from '../../shared/icons/shop/shop-photo.svg';
import PopupIcon7 from '../../shared/icons/shop/shop-target.svg';
import PopupIcon8 from '../../shared/icons/shop/shop-13.svg';
import BorderIcon from "../../components/BorderIcon";
import CircleIconBox from "../../components/CircleIconBox";

class Shops extends StandardPageComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPopup1: false,
      shopPopup2: false
    };
  }

  togglePopup1() {
    this.setState({
      showPopup1: !this.state.showPopup1
    });
  }

  togglePopup2() {
    this.setState({
      showPopup2: !this.state.showPopup2
    });
  }

  render() {
    return (
      <>
        <StaticQuery
          query={graphql`
            query {
              top: file(relativePath: { eq: "kompetencje/shops/top.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              compare1: file(relativePath: { eq: "kompetencje/shops/compare-left.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              compare2: file(relativePath: { eq: "kompetencje/shops/compare-right.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              screen: file(relativePath: { eq: "kompetencje/shops/screen.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              tab1_photo1: file(relativePath: { eq: "kompetencje/shops/tab1_photo1.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              tab5_photo: file(relativePath: { eq: "kompetencje/shops/tab5.png" }) {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
                    ...GatsbyImageSharpFluid
                  }
                }
              },
              shipment_inpost: file(relativePath: { eq: "kompetencje/shops/shipment/inpost.png" }) {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              },
              shipment_ups: file(relativePath: { eq: "kompetencje/shops/shipment/ups.png" }) {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              },
              shipment_siodemka: file(relativePath: { eq: "kompetencje/shops/shipment/siodemka.png" }) {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              },
              shipment_dpd: file(relativePath: { eq: "kompetencje/shops/shipment/dpd.png" }) {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              },
              shipment_pp: file(relativePath: { eq: "kompetencje/shops/shipment/pp.png" }) {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              },
              service_ceneo: file(relativePath: { eq: "kompetencje/shops/systems/ceneo.jpg" }) {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              },
              service_criteo: file(relativePath: { eq: "kompetencje/shops/systems/criteo.jpg" }) {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              },
              service_facebook: file(relativePath: { eq: "kompetencje/shops/systems/facebook.jpg" }) {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              },
              service_google: file(relativePath: { eq: "kompetencje/shops/systems/google.jpg" }) {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              },
              service_opineo: file(relativePath: { eq: "kompetencje/shops/systems/opineo.jpg" }) {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              },
              service_smsapi: file(relativePath: { eq: "kompetencje/shops/systems/smsapi.jpg" }) {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              },
              payment_payu: file(relativePath: { eq: "kompetencje/shops/payments/payu.png" }) {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              },
              payment_przelewy24: file(relativePath: { eq: "kompetencje/shops/payments/przelewy24.png" }) {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              },
              payment_paypal: file(relativePath: { eq: "kompetencje/shops/payments/paypal.png" }) {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              },
              payment_sagepay: file(relativePath: { eq: "kompetencje/shops/payments/sagepay.png" }) {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              },
              payment_dotpay: file(relativePath: { eq: "kompetencje/shops/payments/dotpay.png" }) {
                childImageSharp {
                  fixed(width: 300) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          `}
          render={data => (
            <div className="page page--small-space page--padding-bottom-mobile page--static page--www">

              <Section
                modifiers={['text-full', 'padding-x']}
                modifiersLayout={['padding']}
              >
                <SectionColumn>
                  <TextContent modifiers={['light-big', 'center-mobile']}>
                    <h1>
                      <FormattedHTMLMessage id="competences.shops.text1" />
                    </h1>
                  </TextContent>
                  <TextContent modifiers={['light-medium', 'light', 'center-mobile']}>
                    <FormattedHTMLMessage id="competences.shops.text2" />
                  </TextContent>
                </SectionColumn>
              </Section>

              <Image image={data.top.childImageSharp.fluid} modifiers={['padding-y-small']} />

              <Section modifiers={['columns', 'padding-x']} modifiersLayout={['padding']}>
                <SectionColumn modifiers={['no-padding-top-mobile']}>
                  {this.state.showPopup1 &&
                    <Popup closePopup={this.togglePopup1.bind(this)}>
                      <PopupHeading icon={PopupIcon1}>
                        <h2>
                          Planowanie strategiczne<br/>
                          Twojego e-sklepu
                        </h2>
                      </PopupHeading>
                      <PopupContent>
                        <LineItems>
                          <LineTop>
                            <div label="Analiza celów biznesowych projektu">
                              Zadajemy mnóstwo pytań, aby zrozumieć Twój biznes i zaproponować najlepsze rozwiązania
                            </div>
                            <div label="Analiza grupy docelowej">
                              Projektując sklep internetowy na pierwszym miejscu stawiamy jego przyszłych użytkowników, których musimy poznać jak najlepiej
                            </div>
                            <div label="Analiza konkurencji">
                              Badamy rynek i otoczenie konkurencyjne, aby zapewnić Ci dostosowanie do standardów, ale i elementy, które wyróżnią Twoją ofertę
                            </div>
                          </LineTop>
                          <LineBottom>
                            <div label="Badanie ruchu użytkowników aktualnego sklepu">
                              Korzystając z Google Analitycs, badamy, które rozwiązania w obecnie posiadanym sklepie sprawdziły się, a które wymagają optymalizacji
                            </div>
                            <div label="Stworzenie strategii realizacji projektu">
                              Wspólnie przygotujemy zarys funkcjonalności sklepu oraz strategię wdrożenia sklepu i wszelkich integracji z systemami zewnętrznymi
                            </div>
                            <div label="Opracowanie specyfikacji funkcjonalnej projektu">
                              W oparciu o ustalone cele biznesowe i strategię tworzymy dla Ciebie prototyp sklepu w formie szczegółowych makiet funkcjonalnych
                            </div>
                          </LineBottom>
                        </LineItems>
                      </PopupContent>
                    </Popup>
                  }
                  <TextContent modifiers={['normal', 'bold']}>
                    <h3>
                      Planowanie strategiczne<br/>
                      Twojego sklepu internetowego
                    </h3>
                  </TextContent>
                  <Spacer modifiers={['medium']} />
                  <TextContent modifiers={['small']}>
                    Na wstępnym etapie analizujemy Twoje cele biznesowe, branżę, ofertę, grupę docelową oraz konkurencję
                    i przygotowujemy fachową specyfikację projektu oraz makiety funkcjonalne. Doradzimy Ci także, wskazując
                    na sprawdzone rozwiązania i technologie, które pozwolą skutecznie zrealizować wymagania Twojego e-biznesu.
                  </TextContent>
                  <Spacer modifiers={['medium']} />
                  <BardziejButton title="global.more" onClick={this.togglePopup1.bind(this)} />
                </SectionColumn>
                <SectionColumn modifiers={['sticky-right-edge']}>
                  <CompareImage image1={data.compare1.childImageSharp.fluid} image2={data.compare2.childImageSharp.fluid} />
                </SectionColumn>
              </Section>

              <Section modifiers={['columns', 'padding-x', 'border-top']} modifiersLayout={['padding']}>
                <SectionColumn modifiers={['sticky-left-edge', 'padding-right']}>
                  <div>
                    <Img className={`image`} fluid={data.screen.childImageSharp.fluid} />
                  </div>
                </SectionColumn>
                <SectionColumn>
                  {this.state.showPopup2 &&
                  <Popup closePopup={this.togglePopup2.bind(this)}>
                    <PopupHeading icon={PopupIcon2}>
                      <h2>
                        Planowanie strategiczne<br/>
                        Twojego e-sklepu
                      </h2>
                    </PopupHeading>
                    <PopupContent>
                      <BorderGrid modifiers={['2']}>
                        <CircleIconBox>
                          <BorderIcon icon={<PopupIcon3/>}/>
                          <TextContent modifiers={['small']}>
                            Po wypracowaniu makiet funkcjonalnych sklepu, przygotujemy na ich bazie indywidualny projekt graficzny strony głównej oraz podstron wraz z kompleksowym opracowaniem treści.
                          </TextContent>
                        </CircleIconBox>
                        <CircleIconBox>
                          <BorderIcon icon={<PopupIcon4/>}/>
                          <TextContent modifiers={['small']}>
                            Nad etapem koncepcyjno-graficznym czuwa kilkuosowy zespół doświadczonych specjalistów,
                            na czele ze strategiem e-commerce oraz projektanem użyteczności UX.
                          </TextContent>
                        </CircleIconBox>
                        <CircleIconBox>
                          <BorderIcon icon={<PopupIcon5/>}/>
                          <TextContent modifiers={['small']}>
                            W razie potrzeby przygotujemy dla Ciebie dodatkowe materiały graficzne, np. banery, infografiki, wykresy, etc.
                          </TextContent>
                        </CircleIconBox>
                        <CircleIconBox>
                          <BorderIcon icon={<PopupIcon6/>}/>
                          <TextContent modifiers={['small']}>
                            Dobierzemy i zakupimy z banku zdjęć profesjonalne fotografie i grafiki polecane przez naszych
                            projektantów na potrzeby realizacji projektu graficznego.
                          </TextContent>
                        </CircleIconBox>
                        <CircleIconBox>
                          <BorderIcon icon={<PopupIcon7/>}/>
                          <TextContent modifiers={['small']}>
                            Zawsze projektujemy indywidualnie – dobieramy najlepsze rozwiązania w oparciu o założone cele
                            biznesowe i gwarantujemy pełne doradztwo na każdym etapie realizacji projektu.
                          </TextContent>
                        </CircleIconBox>
                        <CircleIconBox>
                          <BorderIcon icon={<PopupIcon8/>}/>
                          <TextContent modifiers={['small']}>
                            Otwieramy się na rozwój opracowanego projektu w celu poprawy konwersji - badamy zastosowane
                            rozwiązania i ruch użytkowników, a następnie optymalizujemy to, co wskazane
                          </TextContent>
                        </CircleIconBox>
                      </BorderGrid>
                    </PopupContent>
                  </Popup>
                  }
                  <TextContent modifiers={['normal', 'bold']}>
                    <h3>
                      Indywidualny projekt graficzny sklepu dostosowany do Twojej branży i grupy docelowej
                    </h3>
                  </TextContent>
                  <Spacer modifiers={['medium']} />
                  <TextContent modifiers={['small']}>
                    Tworząc design Twojego sklepu, troszczymy się o efektywność i efektowność - zapewniamy oryginalność
                    koncepcji oraz troskę o maksymalną użyteczność. Nasz 5-osobowy dział kreatywny na czele ze strategiem
                    i projektantem użyteczności UX są w stanie zaprojektować każdą wizję.
                  </TextContent>
                  <Spacer modifiers={['medium']} />
                  <BardziejButton title="global.more" onClick={this.togglePopup2.bind(this)} />
                </SectionColumn>
              </Section>

              <Section modifiers={['columns', 'padding-x', 'border-top']} modifiersLayout={['padding']}>
                <SectionColumn>
                  <TextContent modifiers={['normal', 'bold']}>
                    <h3>
                      Dedykowany sklep internetowy<br/>
                      - Twój e-biznes pod pełną kontrolą
                    </h3>
                  </TextContent>
                  <Spacer modifiers={['medium']} />
                  <TextContent modifiers={['small']}>
                    Oferujemy Ci wydajny i skalowalny system sklepowy, który pozwoli na zaawansowane, ale intuicyjnie
                    zarządzanie zamówieniami oraz asortymentem Twojego sklepu, szereg integracji z systemami zewnętrznymi
                    oraz możliwość swobodnej rozbudowy.
                  </TextContent>
                  <Spacer modifiers={['medium']} />
                  {/*<BardziejButton title="global.features" href="#" />*/}
                </SectionColumn>
                <SectionColumn modifiers={['sticky-right-edge', 'sticky-right-edge-no-mobile']}>
                  <FeaturesCircle items={[
                    {
                      'icon': <CircleIcon1 />,
                      'info': 'competences.shops.features.text1',
                      position: ['50%', 0]
                    },
                    {
                      'icon': <CircleIcon2 />,
                      'info': 'competences.shops.features.text2',
                      position: ['86%', '16%']
                    },
                    {
                      'icon': <CircleIcon3 />,
                      'info': 'competences.shops.features.text3',
                      position: ['100%', '50%']
                    },
                    {
                      'icon': <CircleIcon4 />,
                      'info': 'competences.shops.features.text4',
                      position: ['86%', '84%']
                    },
                    {
                      'icon': <CircleIcon5 />,
                      'info': 'competences.shops.features.text5',
                      position: ['50%', '100%']
                    },
                    {
                      'icon': <CircleIcon6 />,
                      'info': 'competences.shops.features.text6',
                      position: ['13%', '84%']
                    },
                    {
                      'icon': <CircleIcon7 />,
                      'info': 'competences.shops.features.text7',
                      position: [0, '50%']
                    },
                    {
                      'icon': <CircleIcon8 />,
                      'info': 'competences.shops.features.text8',
                      position: ['16%', '16%']
                    }
                  ]}/>
                </SectionColumn>
              </Section>

              <Section modifiers={['padding-y-small']} modifiersWrapper={['full']}>
                <Section
                  sectionTag="div"
                  modifiers={['text-full', 'padding-x', 'border-top']}
                  modifiersLayout={['padding']}
                >
                  <SectionColumn>
                    <TextContent modifiers={['light-medium', 'bold', 'center']}>
                      <h2>
                        <FormattedHTMLMessage id="competences.shops.text3" />
                      </h2>
                    </TextContent>
                  </SectionColumn>
                </Section>

                <Section sectionTag="div" modifiers={['padding-y-small']} modifiersWrapper={['full']}>
                  <SectionColumn>
                    <Tabs labelTag="h3" className="shop-main-tabs">
                      <div label="Główne moduły systemu">
                        <Section sectionTag="div" modifiers={['columns', 'padding-x', 'padding-y-small']} modifiersLayout={['padding', 'top']}>
                          <SectionColumn>
                            <TextContent modifiers={['normal', 'bold']}>
                              Główne moduły naszego<br/>
                              systemu sklepowego
                            </TextContent>
                            <Tabs showNav={false} showCounter={true} className="shop-inner-tabs">
                              <div label="Moduł paczek i wysyłek">
                                <ListWithHeading
                                  icon={<ListIcon1 />}
                                  titleTag="h4"
                                  title="Moduł paczek i wysyłek"
                                  items={[
                                    'Moduł wysyłek umożliwia definiowanie dowolnej listy sposobów dostawy. Koszt wysyłki można uzależnić od wagi produktów lub od kwoty zamówienia.',
                                    'Remarketing',
                                    'Dodawanie do list nadawczych',
                                    'Dodawanie przesyłki (w trakcie)',
                                    'Wysyłanie przygotowanej listy nadawczej',
                                    'Zmiana statusów',
                                    'Zakładki ze statusami zamówień',
                                    'Drukowanie danych dla magazynu',
                                    'Monitorowanie przesyłek',
                                    'Opcja konfiguracji sposobu przygotowania przesyłek listy nadawcze lub bezpośrednia rejestracja paczki dla pojedynczego zamówienia'
                                  ]}
                                />
                              </div>
                              <div label="Asortyment">
                                <ListWithHeading
                                  icon={<ListIcon2 />}
                                  title="Asortyment"
                                  titleTag="h4"
                                  items={[
                                    'Podział na kategorie o dowolnym zagłębieniu',
                                    'Możliwość przypisywanie do wielu kategorii',
                                    'Produkty powiązane',
                                    'Ustawianie produktu jako: nowość, promocja, wyprzedaż, polecane, bestseller',
                                    'Przypisywanie producentów i tworzenie cech produktów',
                                    'Listy życzeń',
                                    'Opinie o produktach',
                                    'Stany magazynowe',
                                    'Szablony rozmiarów',
                                    'Banery promocyjne',
                                    'Zaawansowana wyszukiwarka'
                                  ]}
                                />
                              </div>
                              <div label="Klienci">
                                <ListWithHeading
                                  icon={<ListIcon3 />}
                                  title="Klienci"
                                  titleTag="h4"
                                  items={[
                                    'Zaawansowana wyszukiwarka',
                                    'Lista klientów z podglądem kwoty zakupów, aktywnego rabacie, zgody na newsletter i statusu aktywności',
                                    'Podgląd zamówień klienta',
                                    'Edycja danych, przypisywanie rabatu indywidualnego, grupy rabatowej, dostępnych opcji płatności'
                                  ]}
                                />
                              </div>
                              <div label="Newsletter">
                                <ListWithHeading
                                  icon={<ListIcon4 />}
                                  title="Newsletter"
                                  titleTag="h4"
                                  items={[
                                    'Definiowanie treści newslettera',
                                    'Definiowanie szablonów produktów do prezentacji w liście',
                                    'Ustawianie formatu listy HTML/Tekst',
                                    'Planowana data wysyłki - Newsletter można wysłać natychmiast lub ustalić datę, w której system go wyśle',
                                    'Przeglądanie osób zapisanych na newsletter'
                                  ]}
                                />
                              </div>
                              <div label="CMS (zarządzanie treścią sklepu)">
                                <ListWithHeading
                                  icon={<ListIcon5 />}
                                  title="CMS (zarządzanie treścią sklepu)"
                                  titleTag="h4"
                                  items={[
                                    'Slajdy na stronie głównej',
                                    'Banery reklamowe',
                                    'Galerie',
                                    'Aktualności',
                                    'Strony informacyjne'
                                  ]}
                                />
                              </div>
                              <div label="Administratorzy">
                                <ListWithHeading
                                  icon={<ListIcon6 />}
                                  title="Administratorzy"
                                  titleTag="h4"
                                  items={[
                                    'Zakładanie kont dostępowych do panelu administracyjnego',
                                    'Przypisywanie szczegółowych uprawnień do modułów i akcji wykonywanych na nich'
                                  ]}
                                />
                              </div>
                              <div label="Statystyki sprzedaży">
                                <ListWithHeading
                                  icon={<ListIcon7 />}
                                  title="Statystyki sprzedaży"
                                  titleTag="h4"
                                  items={[
                                    'Zestawienie sprzedaży miesięczne',
                                    'Zestawienie sprzedaży roczne',
                                    'Popularność produktów',
                                    'Popularność produktów miesięczna',
                                    'Klienci powracający',
                                    'Największe zamówienia'
                                  ]}
                                />
                              </div>
                              <div label="Zamówienia">
                                <ListWithHeading
                                  icon={<ListIcon8 />}
                                  title="Zamówienia"
                                  titleTag="h4"
                                  items={[
                                    'Samodzielnie definiowane statusy zamówień',
                                    'Lista zamówień podzielona na zakładki wg statusów',
                                    'Powiadomienia e-mail, SMS o zmianie statusu',
                                    'Edycja zamówienia, danych klienta oraz listy produktów',
                                    'Notatki publiczne i prywatne',
                                    'Zaawansowana wyszukiwarka'
                                  ]}
                                />
                              </div>
                            </Tabs>
                          </SectionColumn>
                          <SectionColumn modifiers={['sticky-right-edge']}>
                            <Img className={`image`} fluid={data.tab1_photo1.childImageSharp.fluid} />
                          </SectionColumn>
                        </Section>
                      </div>
                      <div label="Opcje marketingowe ">
                        <>
                          <Section sectionTag="div" modifiers={['columns', 'padding-x', 'padding-y-small']} modifiersLayout={['padding', 'top']}>
                            <SectionColumn>
                              <TextContent modifiers={['normal', 'bold']}>
                                Opcje<br/>
                                marketingowe sklepu
                              </TextContent>
                            </SectionColumn>
                            <SectionColumn>
                              <TextContent modifiers={['small']}>
                                System sklepowy Intellect SHOP to szereg funkcjonalności, które pozwolą na skuteczny marketing
                                Twojego sklepu, promocję wybranego asortymentu, czy zarządzanie programem lojalnościowym i kodami
                                rabatowymi, dzięki którym zbudujesz zaufanie i zaangażowanie swoich Klientów. Sprawdź, jakie
                                możliwości Ci oferujemy.
                              </TextContent>
                            </SectionColumn>
                          </Section>
                          <Section sectionTag="div" modifiers={['padding-x', 'padding-y-small']} modifiersLayout={['padding', 'top']}>
                            <SectionColumn>
                              <BorderGrid modifiers={['2']}>
                                <ListWithHeading
                                  icon={<TabIcon1 />}
                                  title="Program lojalnościowy"
                                  items={[
                                    'Zestawienie sprzedaży (miesięczne)',
                                    'Program nalicza klientowi rabat procentowy, który jest zależny od kwoty zamówień jakie złożył klient.',
                                    `Konfigurować można<br/>
                                - wartości jednego punktu<br/>
                                - progi rabatowe<br/>
                                - zakres czasowy, z jakiego system sumuje kwotę zamówień
                                i przelicza na punkty`
                                  ]}
                                />
                                <ListWithHeading
                                  icon={<TabIcon2 />}
                                  title="Kody rabatowe"
                                  items={[
                                    'Generowanie kodów rabatowych',
                                    'Definiowanie, czy kod jest jednorazowy czy wielokrotnego użytku',
                                    'Definiowanie maksymalnej ilości wykorzystań',
                                    'Definiowanie procentowego rabatu',
                                    'Data ważności kodu, data od - do'
                                  ]}
                                />
                                <ListWithHeading
                                  icon={<TabIcon3 />}
                                  title="Grupy rabatowe klientów"
                                  items={[
                                    'Definiowanie grup, do których dopisywani będą klienci',
                                    'Ustalanie procentowe wielkości rabatu',
                                    'Podgląd listy klientów w danej grupie'
                                  ]}
                                />
                                <ListWithHeading
                                  icon={<TabIcon4 />}
                                  title="Promocje"
                                  items={[
                                    'Tworzenie grupy produktów w promocji',
                                    'Ustalanie wielkości rabaty procentowego lub kwotowego',
                                    'Data rozpoczęcia i zakończenia promocji',
                                    'Darmowa wysyłka'
                                  ]}
                                />
                              </BorderGrid>
                            </SectionColumn>
                          </Section>
                        </>
                      </div>
                      <div label="Integracje z systemami kurierskimi">
                        <Section sectionTag="div" modifiers={['columns', 'padding-x', 'padding-y-small']} modifiersLayout={['padding', 'top']}>
                          <SectionColumn modifiers={['no-padding-bottom-medium']}>
                            <TextContent modifiers={['normal', 'bold']}>
                              Integracje z systemami<br/>
                              kurierskimi
                            </TextContent>
                            <TextContent modifiers={['small', 'padding-top']}>
                              <p>
                                Nasz system jest w pełni przygotowany do integracji z systemami kurierskimi: UPS, DPD, Poczta Polska,
                                Siódemka oraz paczkomatami InPost.
                              </p>
                              <p>
                                Integracja udostępnia Państwu m.in.: dodawanie przesyłek z listy zamówień i na karcie zamówienia,
                                drukowanie etykiet, protokołów, przesyłki zwykłe i za pobraniem, generowanie linku do śledzenia zamówień
                                oraz listę paczkomatów InPost w koszyku (zalogowani użytkownicy widzą w pierwszej kolejności paczkomaty
                                najbliższe ich lokalizacji).
                              </p>
                            </TextContent>
                            <BorderGrid modifiers={['2', 'center', 'no-padding-x', 'margin-y']}>
                              <div>
                                <Img className={`image`} fixed={data.shipment_inpost.childImageSharp.fixed} />
                              </div>
                              <div>
                                <Img className={`image`} fixed={data.shipment_ups.childImageSharp.fixed} />
                              </div>
                              <div>
                                <Img className={`image`} fixed={data.shipment_siodemka.childImageSharp.fixed} />
                              </div>
                              <div>
                                <Img className={`image`} fixed={data.shipment_dpd.childImageSharp.fixed} />
                              </div>
                              <div>
                                <Img className={`image`} fixed={data.shipment_pp.childImageSharp.fixed} />
                              </div>
                            </BorderGrid>
                          </SectionColumn>
                          <SectionColumn modifiers={['no-padding-top-medium']}>
                            <Steps items={[
                              {
                                "icon": <StepIcon1 />,
                                "title": 'Klient składa zamówienie w Twoim sklepie internetowym'
                              },
                              {
                                "icon": <StepIcon2 />,
                                "title": 'Po dokonaniu płatności, sklep wysyła do systemu kurierskiego informacje o zamówieniu'
                              },
                              {
                                "icon": <StepIcon3 />,
                                "title": 'Po rejestracji przesyłki w systemie kurierskim, drukujesz dla niej list przewozowy'
                              },
                              {
                                "icon": <StepIcon4 />,
                                "title": 'Naklejasz list przewozowy na przesyłkę, a kurier odbiera ją w wyznaczonym przez Ciebie terminie'
                              }
                            ]} />
                          </SectionColumn>
                        </Section>
                      </div>
                      <div label="Integracje z systemami zewnętrznymi">
                        <>
                          <Section sectionTag="div" modifiers={['padding-x', 'padding-y-small']} modifiersLayout={['padding', 'top']}>
                            <SectionColumn>
                              <TextContent modifiers={['normal', 'bold']}>
                                Integracje z systemami zewnętrznymi
                              </TextContent>
                            </SectionColumn>
                          </Section>
                          <Section sectionTag="div" modifiers={['padding-x', 'padding-y-small']} modifiersLayout={['padding', 'top']}>
                            <SectionColumn>
                              <BorderGrid modifiers={['2']}>
                                <div className='service-info'>
                                  <Img className={`image`} fixed={data.service_ceneo.childImageSharp.fixed} />
                                  <ListWithHeading
                                    title="Ceneo"
                                    items={[
                                      'Przypisywanie do kategorii w sklepie kategorii',
                                      'Automatyczne i ręczne generowanie pliku XML'
                                    ]}
                                  />
                                </div>
                                <div className='service-info'>
                                  <Img className={`image`} fixed={data.service_opineo.childImageSharp.fixed} />
                                  <ListWithHeading
                                    title="Opineo"
                                    items={[
                                      'Dodanie kodu JS zgłaszającego zakupione towary do systemu Opineo'
                                    ]}
                                  />
                                </div>
                                <div className='service-info'>
                                  <Img className={`image`} fixed={data.service_smsapi.childImageSharp.fixed} />
                                  <ListWithHeading
                                    title="SMS Api"
                                    items={[
                                      'Powiadomienia o zmianie statusów zamówienia'
                                    ]}
                                  />
                                </div>
                                <div className='service-info'>
                                  <Img className={`image`} fixed={data.service_facebook.childImageSharp.fixed} />
                                  <ListWithHeading
                                    title="Facebook"
                                    items={[
                                      'Lubię to',
                                      'Udostępnij',
                                      'Publikacja newslettera na tablicy'
                                    ]}
                                  />
                                </div>
                                <div className='service-info'>
                                  <Img className={`image`} fixed={data.service_google.childImageSharp.fixed} />
                                  <ListWithHeading
                                    title="Google"
                                    items={[
                                      'Merchant z obsługą wariantów',
                                      'Remarketing - dodawanie kodu z panelu administracyjnego',
                                      'Analytics - dodawanie kodu z panelu administracyjnego'
                                    ]}
                                  />
                                </div>
                                <div className='service-info'>
                                  <Img className={`image`} fixed={data.service_criteo.childImageSharp.fixed} />
                                  <ListWithHeading
                                    title="Criteo"
                                    items={[
                                      'Generowanie katalogu produktów w postaci XML',
                                      'Powiadomienia Criteo (kod JS) o nowej sprzedaży'
                                    ]}
                                  />
                                </div>
                              </BorderGrid>
                            </SectionColumn>
                          </Section>
                        </>
                      </div>
                      <div label="Integracje z płatnościami online">
                        <Section sectionTag="div" modifiers={['columns', 'padding-x', 'padding-y-small']} modifiersLayout={['padding']}>
                          <SectionColumn>
                            <TextContent modifiers={['normal', 'bold']}>
                              Integracje z operatorami<br/>
                              płatności on-line
                            </TextContent>
                            <TextContent modifiers={['small', 'padding-top']}>
                              <p>
                                Nasz system sklepowy posiada także kompletny moduł do obsługi płatności internetowych operatorów:
                                PayU, DotPay, Przeelwy24, PayPal, SagePay. W ramach przedstawionej oferty oferujemy Państwu konfigurację
                                jednego systemu płatności, a na Państwa życzenie z dodatkowymi operatorami.
                              </p>
                            </TextContent>
                            <BorderGrid modifiers={['2', 'center', 'no-padding-x', 'margin-y']}>
                              <div>
                                <Img className={`image`} fixed={data.payment_payu.childImageSharp.fixed} />
                              </div>
                              <div>
                                <Img className={`image`} fixed={data.payment_przelewy24.childImageSharp.fixed} />
                              </div>
                              <div>
                                <Img className={`image`} fixed={data.payment_paypal.childImageSharp.fixed} />
                              </div>
                              <div>
                                <Img className={`image`} fixed={data.payment_sagepay.childImageSharp.fixed} />
                              </div>
                              <div>
                                <Img className={`image`} fixed={data.payment_dotpay.childImageSharp.fixed} />
                              </div>
                            </BorderGrid>
                          </SectionColumn>
                          <SectionColumn modifiers={['sticky-right-edge']}>
                            <Img className={`image`} fluid={data.tab5_photo.childImageSharp.fluid} />
                          </SectionColumn>
                        </Section>
                      </div>
                      <div label="Dodatkowe moduły i rozszerzenia">
                        <>
                          <Section sectionTag="div" modifiers={['columns', 'padding-x', 'padding-y-small']} modifiersLayout={['padding', 'top']}>
                            <SectionColumn>
                              <TextContent modifiers={['normal', 'bold']}>
                                Dodatkowe moduły<br/>
                                i rozszerzenia
                              </TextContent>
                            </SectionColumn>
                            <SectionColumn>
                              <TextContent modifiers={['small']}>
                                Nasz system sklepowy możemy rozszerzyć o dodatkowe integracje z zewnętrznymi systemami oraz
                                wdrożyć jego kolejne wersje językowe. Poza standardowymi opcjami i modułami, Twój panel
                                administracyjny możemy też rozszerzyć o dedykowane mechanizmy, które przygotujemy na Twoje życzenie
                                - Intellect SHOP zapewnia nieorganiczną elastyczność pod kątem rozbudowy.
                              </TextContent>
                            </SectionColumn>
                          </Section>
                          <Section sectionTag="div" modifiers={['padding-x', 'padding-y-small']} modifiersLayout={['padding', 'top']}>
                            <SectionColumn>
                              <BorderGrid modifiers={['2']}>
                                <ListWithHeading
                                  icon={<TabIcon5 />}
                                  title="Subiekt"
                                  items={[
                                    'Synchronizacja kartotek produktów, cen, stanów magazynowych, zamówień'
                                  ]}
                                />
                                <ListWithHeading
                                  icon={<TabIcon5 />}
                                  title="Pc-Market INSOFT"
                                  items={[
                                    'Synchronizacja kartotek produktów, cen, stanów magazynowych, zamówień'
                                  ]}
                                />
                                <ListWithHeading
                                  icon={<TabIcon7 />}
                                  title="Allegro"
                                  items={[
                                    'Dwustronna synchronizacja: pobieranie zamówień i wystawianie aukcji',
                                    'Obsługa wielu kont Allegro',
                                    'Tworzenie szablonów aukcji wraz z zapisywaniem domyślnych pól Allegro',
                                    'Kreacja i konfiguracja indywidualnego szablonu Allegro do wystawiania aukcji'
                                  ]}
                                />
                                <ListWithHeading
                                  icon={<TabIcon8 />}
                                  title="Wersje językowe"
                                  items={[
                                    'Obsługa wielu wersji językowych',
                                    'Waluty',
                                    'Kursy walut',
                                    'Interfejs panelu administracyjnego w wersji PL, EN'
                                  ]}
                                />
                                <ListWithHeading
                                  icon={<TabIcon6 />}
                                  title="Google"
                                  items={[
                                    'Merchant z obsługą wariantów'
                                  ]}
                                />
                              </BorderGrid>
                            </SectionColumn>
                          </Section>
                        </>
                      </div>
                    </Tabs>
                  </SectionColumn>
                </Section>
              </Section>

{/*              <Section modifiers={['padding-y-small', 'border-top']} modifiersWrapper={['wider']}>
                <SectionColumn>
                  <TextBox bgColor="#f07e00" modifiers={['smaller']} href="http://sklep.intellect.pl/admin">
                    <TextContent modifiers={['normal', 'white']}>
                      <strong>Sprawdź</strong> demo sklepu internetowego on-line
                    </TextContent>
                  </TextBox>
                </SectionColumn>
              </Section>*/}

              <Section modifiers={['padding-x', 'padding-y-small']}>
                <SectionColumn>
                  <TextContent modifiers={['normal', 'bold', 'center']}>
                    <h2>
                      Nasi Klienci uwielbiają Intellect Shop. Zobacz opinie
                    </h2>
                  </TextContent>
                </SectionColumn>
                <Reviews
                  items={[
                    {
                      'text': "Powierzony Intellect projekt naszego sklepu internetowego został zrealizowany sprawnie i z zachowaniem wysokiej jakości. Na każdym etapie współpracy mogliśmy liczyć na fachową obsługę, wiedzę, doświadczenie i pomysłowość specjalistów agencji. Szczególnie cenne okazało się także wsparcie techniczne w zakresie migracji bazy danych do nowej wersji sklepu",
                      'author': "Dorota Jaworska / sklep internetowy TMK"
                    },
                    {
                      'text': "Jesteśmy niezwykle wdzięczni za profesjonalne wykonanie, doradztwo techniczne i terminowość wykonania naszego sklepu internetowego przez firmę Intellect. Opracowany projekt graficzny spełnił wszystkie nasze oczekiwania i cele biznesowe, a  wdrożony system administracyjny  jest niezwykle intuicyjny i służy nam w zarządzaniu sprzedażową każdego dnia",
                      'author': 'Bogdan Buczek / firma Jubiler Sezam'
                    },
                    {
                      'text': 'Agencja interaktywna Intellect wykazała się dużą kreatywnością oraz wiedzą w zakresie wprowadzanych w naszym projekcie internetowym rozwiązań. Przygotowana witryna stała się profesjonalną wizytówką naszej firmy, a je obsługa od strony administracyjnej jest prosta i zapewnia wiele możliwości zarządzania treścią oraz produktami.',
                      'author': 'Daniel Kurek / firma SecPol'
                    }
                  ]}
                />
              </Section>

            </div>
          )}
        />
        <ContactSlide standalone={true} titleTag="h2" />
      </>
    )
  }
}

const customProps = {
  localeKey: 'competences',
};

export default withLayout(customProps)(injectIntl(Shops));
