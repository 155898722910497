export const debounce = (fn, delay = 500) => {
  let timer, args;

  function debounced() {
    args = Array.prototype.slice.call(arguments);

    window.clearTimeout(timer);
    timer = window.setTimeout(() => {
      fn.apply(null, args);
    }, delay);
  }

  return debounced;
};

export const debounced = debounce(cb => {
  if (typeof cb === "function") cb();
}, 500);
