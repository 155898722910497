import React from 'react';
import PropTypes from "prop-types";
import TextContent from "../Typography/TextContent";

import "./LineItems.scss";

function prepareItems(data) {
  const itemsArray = [];
  React.Children.toArray(data).forEach(item =>
    itemsArray.push(<LineItem key={item.props.label} item={item} />)
  );
  return itemsArray;
}

const LineItem = ({item}) => (
  <div className="line-item">
    <div className="line-item__title">
      <TextContent modifiers={['small', 'bold']}>
        {item.props.label}
      </TextContent>
    </div>
    <div className="line-item__content">
      <TextContent modifiers={['small']}>
        {item}
      </TextContent>
    </div>
  </div>
);

export const LineTop = ({children}) => (
  <div className="line-items__top">
    {prepareItems(children)}
    <div className="line" />
  </div>
);

export const LineBottom = ({children}) => (
  <div className="line-items__bottom">
    {prepareItems(children)}
  </div>
);

const LineItems = ({children}) => (
  <div className="line-items">
    {children}
  </div>
);

LineItems.propTypes = {
  children: PropTypes.instanceOf(Array).isRequired
};

export default LineItems;
