import React from 'react';

import "./CircleIconBox.scss";

const CircleIconBox = ({children}) => (
  <div className="circle-icon-box">
    {children}
  </div>
);

export default CircleIconBox;
