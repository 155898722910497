import React from 'react';

import "./BorderIcon.scss";

const BorderIcon = ({className, style, icon}) => (
  <div style={style} className={`border-icon ${className}`}>
    {icon}
  </div>
);

BorderIcon.defaultProps = {
  className: '',
  style: {}
};

export default BorderIcon;
