import React, {Component} from 'react';
import Img from "gatsby-image";
import {graphql, StaticQuery} from "gatsby";
import {debounce} from "../../../Tools/Tools";

import "./CompareImage.scss";

import ArrowLeft from '../../../shared/icons/shop/shop-arrow-left.svg';
import ArrowRight from '../../../shared/icons/shop/shop-arrow-right.svg';
import Loader from "../../Loader";

const defaultProps = {
  handlerLineWidth: 6
};

class CompareImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      handlerPositionPercentage: 0.5, // 0 to 1
      imageWidth: 1, // hack for gatsby lazy load
      allImagesLoaded: false,
    };

    this.containerRef = React.createRef();
    this.leftImageRef = React.createRef();
    this.rightImageRef = React.createRef();

    this.mockImgLoaded = false;
    this.rightImgLoaded = false;
    this.leftImgLoaded = false;
  }

  componentDidMount() {
    const containerElement = this.containerRef.current;

    window.addEventListener('resize', debounce(() => {
      this.setImagesSize();
    }, 100));

    containerElement.addEventListener('touchstart', this.startSliding);
    window.addEventListener('touchend', this.finishSliding);

    containerElement.addEventListener('mousedown', this.startSliding);
    window.addEventListener('mouseup', this.finishSliding);
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevState.allImagesLoaded === false &&
      this.state.allImagesLoaded === true
    ) {
      this.setImagesSize();
    }
  };

  componentWillUnmount = () => {
    this.finishSliding();
    window.removeEventListener('mouseup', this.finishSliding);
    window.removeEventListener('touchend', this.finishSliding);
  };

  setImagesSize = () => {
    if (this.rightImageRef.current && this.rightImageRef.current.imageRef.current) {
      this.setState({
        imageWidth: this.rightImageRef.current.imageRef.current.getBoundingClientRect().width,
      });
    }
  };

  startSliding = e => {
    // Prevent default behavior other than mobile scrolling
    if (!('touches' in e)) {
      e.preventDefault();
    }

    this.handleSliding(e);

    window.addEventListener('mousemove', this.handleSliding);
    window.addEventListener('touchmove', this.handleSliding);
  };

  finishSliding = () => {
    window.removeEventListener('mousemove', this.handleSliding);
    window.removeEventListener('touchmove', this.handleSliding);
  };

  handleSliding = event => {
    const e = event || window.event;

    const cursorXfromViewport = e.touches ? e.touches[0].pageX : e.pageX;

    const cursorXfromWindow = cursorXfromViewport - window.pageXOffset;

    const imagePosition = this.rightImageRef.current.imageRef.current.getBoundingClientRect();
    let pos = cursorXfromWindow - imagePosition.left;

    const minPos = this.props.handlerLineWidth / 2;
    const maxPos = this.state.imageWidth + this.props.handlerLineWidth / 2;

    if (pos < minPos) pos = minPos;
    if (pos > maxPos) pos = maxPos;

    this.setState({
      handlerPositionPercentage: pos / this.state.imageWidth,
    });
  };

  onMockImageLoaded = () => {
    this.mockImgLoaded = true;

    if (this.mockImgLoaded && this.rightImgLoaded && this.leftImgLoaded) {
      this.setState({ allImagesLoaded: true });
    }
  };

  onRightImageLoaded = () => {
    this.rightImgLoaded = true;

    if (this.mockImgLoaded && this.rightImgLoaded && this.leftImgLoaded) {
      this.setState({ allImagesLoaded: true });
    }
  };

  onLeftImageLoaded = () => {
    this.leftImgLoaded = true;
    if (this.mockImgLoaded && this.rightImgLoaded && this.leftImgLoaded) {
      this.setState({ allImagesLoaded: true });
    }
  };

  render = () => {
    const {data, image1, image2, handlerLineWidth} = this.props;

    const styles = {
      handler: {
        left:
          this.state.imageWidth * this.state.handlerPositionPercentage - handlerLineWidth / 2 +
          'px',
      },
      leftImage: {
        clip: `rect(auto, ${this.state.imageWidth * this.state.handlerPositionPercentage}px, auto, auto)`
      }
    };

    return (
      <div className="compare-box">
        <div className="compare-box__mock">
          <Img
            onLoad={this.onMockImageLoaded}
            className={`image`} fluid={data.mock.childImageSharp.fluid} />
        </div>
          <div
            className="compare-box__images"
            ref={this.containerRef}
          >
            {!this.state.allImagesLoaded && <Loader/>}
            <div
              className="compare-box__handler"
              style={styles.handler}
            >
              <div className="compare-box__handler-circle">
                <ArrowLeft/>
                <ArrowRight/>
              </div>
            </div>
            <Img
              onLoad={this.onRightImageLoaded}
              className={`image image--right`} fluid={image2} ref={this.rightImageRef}/>
            <Img
              onLoad={this.onLeftImageLoaded}
              className={`image image--left`} fluid={image1} ref={this.leftImageRef}
              style={styles.leftImage}
            />
          </div>
      </div>
    )
  }
};

CompareImage.defaultProps = defaultProps;

export default props => (
  <StaticQuery
    query={graphql`
      query {
        mock: file(relativePath: { eq: "kompetencje/shops/compare-screen.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100, srcSetBreakpoints: [320, 360, 620, 640, 768, 1024, 1200, 1366, 1920]) {
              ...GatsbyImageSharpFluid
            }
          }
        },
      }
    `}
    render={data => <CompareImage data={data} {...props} />}
  />
);
