import React from 'react';
import PropTypes from 'prop-types';

import './Steps.scss';
import TextContent from "../Typography/TextContent";

const Step = ({icon, title}) => (
  <div className="step">
    <div className="step__icon">{icon}</div>
    <div className="step__title">
      <TextContent modifiers={['small']}>
        {title}
      </TextContent>
    </div>
  </div>
);

const Steps = ({items}) => (
  <div className="steps">
    {items.map(({icon, title}) => <Step key={title} icon={icon} title={title} />)}
  </div>
);

Steps.propTypes = {
  items: PropTypes.array
};

export default Steps;
