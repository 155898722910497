import React from 'react';
import PropTypes from "prop-types";

import "./BorderGrid.scss";

const BorderGrid = ({children, modifiers}) => (
  <div className={`border-grid ${modifiers.map(item => "border-grid--" + item).join(' ')}`}>
    {children}
  </div>
);

BorderGrid.defaultProps = {
  modifiers: [],
};

BorderGrid.propTypes = {
  modifiers: PropTypes.array,
};

export default BorderGrid;
