import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './FeaturesCircle.scss';
import TextContent from "../Typography/TextContent";
import BorderIcon from "../BorderIcon";
import {FormattedHTMLMessage} from "react-intl";

class CircleItem extends Component {
  handleMouseEnter = (e, current) => {
    const {handleMouseEnter} = this.props;
    handleMouseEnter(e, current);
  };

  render() {
    const {icon, position, isActive} = this.props;
    const styles = {
      left: position[0],
      top: position[1]
    };
    const {handleMouseEnter} = this;


    return (
      <div onMouseEnter={e => handleMouseEnter(e, this)}>
        <BorderIcon style={styles} icon={icon} className={`circle-item ${isActive ? 'active' : ''}`} />
      </div>
    )
  }
};

class FeaturesCircle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info: this.props.items[0].info || '',
      icon: this.props.items[0].icon || 0,
      isHovered: 0
    }
  }

  handleMouseEnter = (e, current) => {
    this.setState({
      info: current.props.info,
      icon: current.props.icon,
      isHovered: current.props.index
    })
  };

  render() {
    const {items} = this.props;
    const {isHovered} = this.state;
    const elements = items.map(({info, icon, position}, index) => <CircleItem isActive={isHovered === index} index={index} key={index} info={info} icon={icon} position={position} handleMouseEnter={this.handleMouseEnter} />);
    return (
      <div className="features-circle">
        {elements}
        <div className="features-circle__active">
          <div className="features-circle__icon">
            {this.state.icon}
          </div>
          <div className="features-circle__info">
            <TextContent modifiers={['small']}>
              <FormattedHTMLMessage id={this.state.info} />
            </TextContent>
          </div>
        </div>
      </div>
    )
  }
};

CircleItem.defaultProps = {
  position: [0,0]
};

FeaturesCircle.propTypes = {
  items: PropTypes.array.isRequired
};

export default FeaturesCircle;
