import React from 'react';
import PropTypes from 'prop-types';

import QuotationIcon from '../../shared/icons/shop/shop-quatation.svg';

import "./Reviews.scss";

const Review = ({text, author}) => (
  <div className="review">
    <div className="review__icon"><QuotationIcon /></div>
    <div className="review__text">{text}</div>
    <div className="review__author">{author}</div>
  </div>
);

const Reviews = ({items}) => (
  <div className="reviews">
    {items.map(({text, author}, index) => <Review key={index} text={text} author={author} />)}
  </div>
);

Reviews.propTypes = {
  items: PropTypes.array.isRequired
};

export default Reviews;
